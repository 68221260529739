.product .cardsWrepper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin: 40px 0;
}

.product .cardWrepper {
    width: 300px;
    padding: 0;
    overflow: hidden;
    box-shadow: none;
    height: max-content;
    border: 1px solid #ccc;
}

.product .cardWrepper .cardImage {
    border-radius: 0;
    border: 1px solid #ccc;
    width: 90%;
    margin: 15px auto;
    border-radius: 10px;
    height: 200px;
}

.product .cardWrepper .cardContent {
    padding: 20px;
}

.product .cardWrepper .cardContent .title {
    font-size: 22px;
}

.product .cardWrepper .cardContent .cardFooter {}

.product .cardWrepper  .cardFooter {
    text-align: center;
}

.product .cardWrepper .cardFooter button {
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-family: 'Open Sans';
    background: #fff;
    border-color: transparent;
    border-top: 1px solid #ccc;
    /* border-radius: 10px; */
    /* margin: 10px auto; */
    background: #eee;
}