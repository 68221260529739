.cardWrepper {
    background: #fff;
    width: 240px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0px #e7e7e7;
    height: 330px;
    padding: 20px;
    /* margin: 40px 0; */
    cursor: pointer;
    display: flex;
    flex-direction: column;  /* Stack children vertically */
    justify-content: space-between; /* Ensures proper spacing */
    transition: 0.2s all ease-in-out;
}

.cardWrepper:hover {
    transform: scale(0.9);
}

.cardWrepper .cardImage {
    background: #FFFCE8;
    width: 100%;
    height: 160px;
    text-align: center;
    border-radius: 15px;
}

.cardWrepper .cardImage img {
    width: 80%;
    height: 90%;
    object-fit: contain;
}

.cardContent {
    font-family: 'Ponomar';
    padding: 16px 0;
    flex-grow: 1;
}

.cardWrepper .cardContent .title {
    margin: 0;
    font-weight: 700;
}

.cardWrepper .cardContent .tag {
    font-size: 18px;
    color: #474747;
    display: block;
    line-height: 18px;
    margin-top: 5px;
    font-family: "Aref Ruqaa";
}

.cardFooter {
    margin-top: auto;
    text-align: right;
}

.cardFooter button {
    font-family: 'Ponomar';
    background: #FFFCE8;
    padding: 7px 20px;
    border: 1px solid #FFB202;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    transition: all .3s ease;
}

.cardFooter button:hover {
    background: #FFB202;
}