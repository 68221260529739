.contact .content {
    padding: 60px 0;
}

.contact .content .map {
    border-radius: 20px;
    overflow: hidden;
    filter: grayscale(1);
    transition: all .3s ease;
    height: 100%;
}

.contact .content .map:hover {
    filter: grayscale(0);
}

.form {
    background: #FFFADE;
    padding: 20px;
    border-radius: 20px;
}

.form h1 {
    font-family: 'Great Vibes';
    font-size: 35px;
    border-bottom: 1px solid #FFB302;
    padding-bottom: 5px;
    margin-bottom: 20px;
    color: #FFB302;
    text-align: center;
    font-weight: 600;
    /* letter-spacing: 4px; */
}

.form form {
    border: 1px solid #FFB302;
    border-radius: 20px;
    padding: 20px;
    background: #FFF199;
}

.form form label {
    font-family: 'Ponomar';
    font-size: 18px;
}

.form form .input {margin: 5px 0;}

.form form .input button {
    background: #FFB302;
}