.navBar {
    font-family: 'Ponomar';
    background: #BC252A;
    position: relative;
}
.link {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    color: #fff !important;
    font-size: 20px !important;
    margin: 0 10px;
}

.link.btn.btn-nav {
    background: #FFB202;
    padding: 10px 0 !important;
    height: 45px;
    width: 80px;
    font-family: 'Open Sans';
    font-size: 16px !important;
    display: grid;
    align-items: center;
    justify-content: center !important;
    font-weight: 700;
}

.logo img {
    width: 120px;
    
}

nav img.wave {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -18px;
    width: 100%;
    z-index: 111;
}

nav a.logo.navbar-brand {
    color: #fff;
    justify-content: center;
    display: flex
;
}
nav::after {content: "";background: linear-gradient(90deg, rgba(251,175,5,0.8267900910364145) 0%, rgba(255,241,152,1) 58%, rgba(4,149,29,0.779171043417367) 100%);height: 10px;position: absolute;bottom: -10px;left: 0;right: 0;}

.navBar .linksContainer {
    width: 40%;
}

/* .navBar .navbar-brand ~ button ~ .linksContainer {
    text-align: right;
    justify-content: right;
} */

.link::after {
    content: '';
    background-color: #FFB200;
    width: 100%;
    height: 10px;
    display: block;
    position: relative;
    top: 0;
    z-index: 1;
    transform: scale(0, 1);
    transition: transform 0.4s ease;
}

.link:hover::after {
    transform: scale(1,1);
}

.link.active {
    transform: scale(1, 1);
}