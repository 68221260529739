.productContent .brandImage {
    background: #FFFCE8;
    border-radius: 20px;
    padding: 30px;
    width: 100%;
    height: max-content;
}

.brandImage img {
    width: 100%;
    object-fit: contain;
    /* background: #fff6ba;
    border-radius: 20px; */
}

.productContent {
    margin: 50px 0;
}

.productContent .productContentWrepper {
    padding: 0 20px;
    font-family: 'Open Sans';
}

.productContent .productContentWrepper .productName {
    font-family: 'Ponomar';
}

.productContent .productContentWrepper .productTagline {
    border: 1px solid;
    border-right: 0;
    border-left: 0;
    padding: 10px 0;
    display: block;
    /* font-style: italic; */
    font-weight: bold;
    font-size: 20px;
    font-family: "Vazirmatn", sans-serif;
}

.productContent .productContentWrepper .productDesc {
    margin: 15px 0;
}

.productContent .packaging button {
    border: 1px solid #EBEBEB;
    font-family: 'Ponomar';
    font-size: 18px;
    color: #707070;
    font-weight: bold;
    border-radius: 0;
    text-transform: capitalize;
}

.productContent .packaging button:focus, .packaging button:hover {
    border-color: transparent !important;
    color: #707070;
    border-bottom: 1px solid #EBEBEB !important;
}

.productContent .packaging button.active {
    color: #FFAE04 !important;
    border-bottom-color: #FFAE04 !important;
}

.productContent .packaging {
    margin-top: 40px;
    border: 1px solid #EBEBEB;
    padding: 20px;
    border-radius: 20px;
}

.productContent .productTable.table {
    margin-top: 20px;
    border: 1px solid #EBEBEB;
}

.productContent .productTable.table th {
    width: 150px;
    font-weight: 600;
    line-height: 2.5;
    color: rgba(0, 0, 0, 0.74);
    font-size: 16px;
    padding: 5px 17px;
    border-top: 0px;
    border-bottom: 1px solid rgb(229, 229, 229);
    margin: 0px;
    font-family: 'Ponomar';
}

.productContent .productTable.table td {
    font-style: italic;
    text-align: right;
    /* color: rgb(82, 82, 82); */
    border-bottom: 1px solid rgb(229, 229, 229);
    border-left: 1px solid rgb(229, 229, 229);
    margin: 0px;
    padding: 0px 17px;
    font-family: 'Open Sans';
    font-size: 14px;
}

.productContent .productTable.table tr:nth-child(even) {background: #F9F9F9;}