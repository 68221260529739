.testimonial .slide {
    margin: 30px 0;
}

.testimonial  .slide .swiper-slide {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    transition: all .3s ease;
}

.testimonial .slide .swiper-slide-active {
    filter: blur(0px) !important;
    -webkit-filter: blur(0px) !important;
}

