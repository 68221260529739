.testimonialCardWrepper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonialCardWrepper .image {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    overflow: hidden;
}

.testimonialCardWrepper .content .quote {
    font-family: 'Open Sans';
    font-style: italic;
    color: #6E6E6E;
    font-weight: 600;
    font-size: 14px;
}

.testimonialCardWrepper .content {
    margin-left: 10px;
}

.testimonialCardWrepper .content .name {
    font-family: 'Ponomar';
    color: #FDB302;
    font-weight: 700;
    margin: 0;
    letter-spacing: 4px;
}

.testimonialCardWrepper .content .profession {
    font-family: 'Open Sans';
    font-size: 12px;
    position: relative;
    color: #8C8C8C;
    top: -5px;
    font-weight: 400;
}



.testimonialCardWrepper {
    background: #FFFADD;
    padding: 40px 20px;
    border-radius: 20px;
    height: 230px;
    /* width: 600px; */
}
.testimonialCardWrepper.extend {
    padding: 40px 20px;
    border-radius: 20px;
    height: 330px;
    text-align: center;
    /* width: 600px; */
}

.testimonialCardWrepper.extend .image {
    margin: 0 auto;
}