.banner {
    /* background: #FFFCE8; */
    background:  linear-gradient(90deg, rgba(255,250,220,1) 0%, rgba(255,241,152,1) 43%, rgba(255,252,232,1) 100%);
    padding: 80px 0;
    /* margin: 0 20px; */
    border-radius: 20px;
}

.banner h1 {
    font-family: 'Ponomar';
    font-size: 50px;
    /* margin-bottom: 20px; */
}

.banner span {
    font-family: 'Great Vibes';
    font-size: 40px;
    /* margin-bottom: 20px; */
    font-weight: 700;
    display: block;
    position: relative;
    margin-top: -25px;
    color: #FFB202;
    text-align: right;
    left: -100px;
    /* background: #FFF3A2; */
}
.banner p {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 50px;
    font-size: 18px;
}

.banner button {
    background: #ffff;
    border: 1px solid;
    padding: 10px 20px;
    font-family: 'Open Sans';
    font-size: 16px;
}

.banner h1 {
    font-family: 'Ponomar';
}

.banner p {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 50px;
}

.banner button {
    background: #FFB202;
    border: 1px solid #FFB202;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 10px;
    color: #fff;
    font-family: 'Ponomar';
    font-weight: 800;
    transition: all .3s ease;
}
.banner button:hover {
    background: transparent;
    color: #FFB202;
}
.banner .image  {
    position: relative;
}
.banner .image::after  {
    /* background-image: url("../../assets/images/frame2.png");
    content: "";
    background-size: contain;
    position: absolute;
    top: -40px;
    left: -50px;
    right: -19px;
    bottom: -3px;
    z-index: 0;
    background-repeat: no-repeat; */
}

.banner .image img {
    width: 100%;
    height: 430px;
    object-fit: cover;
    border-radius: 50px;
}

.banner .image {
    margin: auto 0 auto auto;
    width: 500px;
}


.products {
    padding: 50px 0;
}

.cardGroup .cardWrepper {margin: 0;box-shadow: none;position: relative;background: none;}

.cardGroup {
    border-radius: 10px;
    box-shadow: 0 0 6px 0px #e7e7e7;
    width: 100% !important;
    padding: 5px;
}

.cardGroup .cardWrepper:first-child::after,.cardGroup .cardWrepper:last-child::after {content: "";position: absolute;background: #eee;top: 60px;bottom: 60px;width: 0.5px;right:0;border-radius:200px;}
.cardGroup .cardWrepper:last-child::after {
    left: 0;
}

.productsWrepper {
    margin-top: 30px;
}

.productsWrepper .carousel {
    width: 100%;
    box-shadow: 0 0 6px 0px #e7e7e7;
    border-radius: 10px;
    padding: 5px;
    justify-content: center;
}

.productsWrepper .carousel:not(.cardGroup) .cardWrepper {
    box-shadow: none;
    width: 100%;
}

.certificates img {
    width: 100px;
    height: 65px;
    object-fit: contain;
}

.certificateWrepper {
    background: #fcfcfc;
    border-radius: 20px;
    padding: 20px;
    margin: 60px 40px 20px 40px;
    justify-content: space-around;
}