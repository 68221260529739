.aboutBanner {
    margin: 0 20px;
    height: 400px;
    overflow: hidden;
    border-radius: 20px;
}

.aboutBanner img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.about .imageContainer img {
    width: 48%;
    height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
}

.about .imageContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.about .imageContainer img:last-child {
    margin-top: 60px;
}

.about .aboutContent .heading {
    font-family: 'Great Vibes';
    text-decoration: none;
    font-size: 80px;
    color: #FFB202;
    margin: 0;
    padding: 0;
    margin-top: 100px;
}

.about .aboutContent h1 {
    margin-top: -75px;
    font-family: 'Ponomar';
    font-size: 65px;
    font-weight: 800;
    margin-bottom: 20px;
}

.about .aboutContent {
    padding: 0 30px;
}

.about .aboutContent p {
    font-family: 'Open Sans';
    font-size: 18px;
    color: #666666;
    font-weight: 600;
    width: 80%;
}

.about .aboutContent p ~ span {
    font-family: 'Open Sans';
    font-weight: 700;
    color: #666666;
    margin-bottom: 10px;
    display: block;
}

.about .aboutContent p ~ h3 {
    font-size: 50px;
    color: #FFB202;
    font-weight: 700;
    margin-bottom: 20px;
}

.about .statics h3 {
    font-size: 40px;
    color: #FFB202;
    font-weight: 700;
}

.about .statics p {
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-size: 14px;
    color: #666666;
    font-weight: 600;
}

.about .statics .desc {
    font-size: 16px;
    text-transform: capitalize;
    line-height: 1.8;
}

.about .customers {
    background: #FFFADE;
    margin: 30px 0px;
    padding: 40px 0;
    text-align: center;
    border-radius: 20px;
}

.about .customers h1 {
    font-family: 'Great Vibes';
}

.about .customers .testimonial .testimonialCardWrepper {
    border: 1px solid #FEB302;
}

.about .certification .context {
    background: #FFFADE;
    padding: 10px 10px;
    border-radius: 20px;
}

.about .certification {
    margin-bottom: 50px;
}

.about .certification h1 {
    font-family: 'Great Vibes';
    font-size: 50px;
    letter-spacing: 4px;
    background: #FFF199;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    line-height: 100px;
    /* box-shadow: 0px 12px 15px 5px #fff7c4; */
    margin-bottom: 20px;
}

.about .certification h1 span {
    color: #BC252A;
    /* font-size: 55px; */
    /* display: block; */
    text-align: justify;
    letter-spacing: 0;
    /* margin-top: -9px; */
}

.about .certification .context p {
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 1.8;
    margin-top: 30px;
    padding: 0 20px;
    /* color: #6E6E6E; */
    /* font-weight: 500; */
}

.about .certification .context p br {
}

.about .certification .line div {
    width: 160px;
    height: 110px;
    background: #fcfcfc;
    margin: 10px 10px 0px 0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.about .certification .line {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about .certification .line div img {
    width: 88px;
    filter: grayscale(80%);
    transition: all .2s ease;
}

.about .certification .line div img:hover {
    filter: grayscale(0%);
}