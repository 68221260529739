
.logo {
    width: 50px;
}

.footerWrepper {
    /* background: #FFFCE8; */
    background:  linear-gradient(90deg, rgba(255,250,220,1) 0%, rgba(255,241,152,1) 43%, rgba(255,252,232,1) 100%);
    padding: 100px 0 50px 0;
}

.heading {
    font-family: 'Ponomar';
    font-weight: 700;
    text-decoration: underline;
    margin-bottom: 8px;
}

.data {list-style: none;padding: 0;font-size: 14px;}

.data li a {
    text-decoration: none;
    color: #212529;
    padding: 4px 0;
    font-family: 'Open Sans';
    display: block;
}

.title {
    font-family: 'Ponomar';
    margin-bottom: 10px;
}

.about {
    font-family: 'Open Sans';
    font-size: 16px;
}


.social a {
    width: 42px;
    background: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
}

.social {
    gap: 8px;
}

.social a img {
    width: 30px;
}

.instagram img {
    width: 100%;
    height: 75px;
    object-fit: cover;
}

.instagram .row > div:nth-child(1),.instagram .row > div:nth-child(3) {
    padding-right: 0;
}

.instagram .row > div:nth-child(2), .instagram .row > div:nth-child(4) {
    padding-left: 0;
}

.instagram .heading {
    padding-bottom: 7px;
}

.tagline {
    background: #FFFADE;
    padding: 25px;
    margin: 50px 20px 0 20px;
    border-radius: 20px;
    text-align: center;
}

.tagline p {
    font-family: 'Ponomar';
    font-weight: 600;
    padding: 0;
    margin: 0;
}

