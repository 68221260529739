@import url('https://fonts.googleapis.com/css2?family=Emblema+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Ponomar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100..900&display=swap');
body::after {
    content: "Site is under construnction!";
    position: fixed;
    right: -92px;
    top: 200px;
    transform: rotate(270deg);
    font-family: 'Ponomar';
    z-index: 10000;
    background: #fcfcfc;
    padding: 2px 15px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 10px 0 #fcfcfc;
    border: 1px solid #9d9d9d;
}

header:has(~ main .pageTitle) {
    background: linear-gradient(90deg, rgba(255, 250, 220, 1) 0%, rgba(255, 241, 152, 1) 43%, rgba(255, 252, 232, 1) 100%);
}
.pageTitle {
    background: linear-gradient(90deg, rgba(255, 250, 220, 1) 0%, rgba(255, 241, 152, 1) 43%, rgba(255, 252, 232, 1) 100%);
    border-radius: 0 0 20px 20px;
    padding: 80px 0 60px 0;
    font-family: 'Ponomar';
}

.pageTitle h4 {
    text-align: center;
    font-size: 28px;
}